.react-datepicker__header--custom {
    background-color: #fff !important;
    border-bottom: none !important;
    padding: 0 !important;
    /*overflow: hidden;*/
}

.react-datepicker__header--custom > p {
    border-radius: 4px 4px 0 0;
}

.react-datepicker__day--outside-month {
    color: #8F8F8C !important;
}

.react-datepicker__day {
    color: #444440;
    width: 2em !important;
    line-height: 2em !important;
    border-width: 1px !important;
    border-color: transparent;
}

.react-datepicker__day:hover {
    background-color: transparent !important;
    border-radius: 2px !important;
    border: 1px solid #444440 !important;
}

/*.react-datepicker__day--keyboard-selected {*/
/*    background-color: transparent !important;*/
/*    color: #1272EF !important;*/
/*    border-radius: 2px !important;*/
/*    border: 1px solid #1272EF !important;*/
/*}*/

/*.react-datepicker__day--keyboard-selected:hover {*/
/*    border: 1px solid #1272EF !important;*/
/*}*/

.react-datepicker__day--selected {
    background-color: transparent !important;
    color: #1272EF !important;
    border-radius: 2px !important;
    border: 1px solid #1272EF !important;
}

.react-datepicker__day--selected:hover {
    border: 1px solid #1272EF !important;
}

.react-datepicker__children-container {
    width: 16.2em !important;
}

.react-datepicker-wrapper {
    display: flex !important;
    width: 100%;
}

.react-datepicker__input-container {
    display: flex !important;
}

.react-datepicker-popper {
    z-index: 3 !important;
}
@use "./landing-variables.scss";

.landingWrapper {
  display: flex;
  flex-direction: column;
  place-items: center center;
  margin: 0;
  padding: 0;
  background-color: landing-variables.$color-black;
  font-family: landing-variables.$base-font-family;
  font-size: landing-variables.$base-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 160%;
  font-weight: 500;
  color: landing-variables.$color-white;

  code {
    font-family: landing-variables.$code-font-family;
  }

  h1 {
    margin: 0 0 8rem;
    font-size: 5rem;
    line-height: 105%;
    font-weight: 700;
  }

  h2 {
    margin: 0 0 7rem;
    font-size: 4rem;
    line-height: 130%;
    font-weight: 700;
  }

  h3 {
    margin: 0 0 6rem;
    font-size: 3rem;
    line-height: 140%;
    font-weight: 700;
  }

  h4 {
    margin: 0 0 4rem;
    font-size: 2rem;
    line-height: 150%;
    font-weight: 700;
  }

  p {
    margin: 0 0 2rem;
    font-size: 1.5rem;
    line-height: 160%;
    font-weight: 500;
  }

  .btn {
    position: relative;
    justify-content: center;
    width: 12rem;
    height: 3.5rem;
    margin: 0;
    overflow: hidden;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: 20px;
    text-decoration: none;
    -webkit-text-decoration: none;

    .shape {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      transition: background 0.4s, transform 0.1s, box-shadow 0.2s;
      border-radius: 2rem;
    }
  }

  .btn.bgColorMain {
    .shape {
      background-color: landing-variables.$color-accent-light;
    }
  }

  .btn.bgColorMain:hover {
    color: #d5cbea;
    .shape {
      background: landing-variables.$color-accent-dark;
    }
  }

  .btn.bgColorWhite {
    color: landing-variables.$color-accent-dark;

    .shape {
      background-color: landing-variables.$color-white;
      border-width: 1px;
      border-color: landing-variables.$color-accent-light;
    }
  }

  .btn.bgColorWhite:hover {
    color: landing-variables.$color-accent-dark;
    .shape {
      background: landing-variables.$color-grey;
    }
  }

  @media (hover: hover) {
    .btn:not([disabled]):hover .shape {
      transition-duration: 0.2s;
    }
  }

  .btn .slot {
    display: grid;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: -1px;
    padding-left: calc(32px + 0vw);
    padding-right: calc(32px + 0vw);
    position: relative;
    text-align: center;
  }

  .gradientButton {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    width: 8rem;
    height: 2.5rem;
    box-sizing: border-box;
    overflow: visible;
    cursor: pointer;
  }

  .gradientButton .gradientButtonBG {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #ce4884 0%, #a056ea 100%);
    border-radius: 1.25rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
  }

  .gradientButton::after {
    content: "Sign In";
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(90deg, #a53869 0%, #8946cd 100%);
    border-radius: 1.3125rem;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    cursor: pointer;
  }

  .signedIn .gradientButton:after {
    content: "Dashboard";
  }

  .gradientButton:hover::after {
    opacity: 1;
  }

  .gradientButton:active::after {
    background: linear-gradient(90deg, #8b2f58 0%, #7037a8 100%);
    color: #bababa;
  }

  .gradientBorder {
    border-radius: 1.3125rem;
    border-style: outset;
    border-color: buttonborder;
    border-image: initial;
    cursor: pointer;
  }

  .gradientBorder:before {
    content: "";
    position: absolute;
    inset: -1px;
    border: 1px solid transparent;
    border-radius: 1.3125rem;
    background: linear-gradient(#e484c3, #9e6ee5) border-box,
      linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: xor;
    cursor: pointer;
  }
}

@media (max-width: 1280px) {
  .landingWrapper {
    font-size: 1rem;
    line-height: 160%;

    h1 {
      margin: 0 0 6rem;
      font-size: 2.5rem;
      line-height: 120%;
    }

    h2 {
      margin: 0 0 5rem;
      font-size: 2rem;
      line-height: 130%;
    }

    h3 {
      margin: 0 0 4rem;
      font-size: 1.75rem;
      line-height: 140%;
    }

    h4 {
      margin: 0 0 3rem;
      font-size: 1.5rem;
      line-height: 150%;
    }

    p {
      margin: 0 0 2rem;
      font-size: 1.1rem;
      line-height: 150%;
    }
  }
}

@media (max-width: 720px) {
  .landingWrapper {
    font-size: 1rem;
    line-height: 160%;

    h1 {
      margin: 0 0 4rem;
      font-size: 1.75rem;
      line-height: 120%;
    }

    h2 {
      margin: 0 0 3rem;
      font-size: 1.5rem;
      line-height: 130%;
    }

    h3 {
      margin: 0 0 3rem;
      font-size: 1.25rem;
      line-height: 140%;
    }

    h4 {
      margin: 0 0 2.5rem;
      font-size: 1.15rem;
      line-height: 150%;
    }

    p {
      margin: 0 0 2rem;
      font-size: 1rem;
      line-height: 160%;
    }

    .btn {
      width: 10rem;
      height: 3rem;
      font-size: 1rem;
    }

    .gradientButton {
      width: 6rem;
      height: 2rem;
    }
  }
}

/* Custom CSS for AG Grid */

body {
    font-family: "Inter", sans-serif;
}

.ag-theme-alpine .ag-root-wrapper {
    border: none;
}

/* Headers */
.ag-theme-alpine .ag-root-wrapper .ag-header .ag-header-cell {
    padding-left: 4px;
    padding-right: 4px;
}

.ag-theme-alpine .ag-root-wrapper .ag-header .ag-header-row {
    font-size: 12px;
}

.ag-theme-alpine
.ag-root-wrapper
.ag-header
.ag-header-cell
.ag-header-cell-comp-wrapper
> div {
    gap: 6px;
    margin: 4px 0;
}

.ag-theme-alpine
.ag-root-wrapper
.ag-header
.ag-header-cell.product-checkbox-header {
    padding-left: 12px;
    padding-right: 12px;
}

.ag-theme-alpine
.ag-root-wrapper
.ag-header
.ag-header-cell.product-size-or-quantity-cell-header {
    text-align: center;
}

.ag-theme-alpine
.ag-root-wrapper
.ag-header
.ag-header-cell
.ag-header-cell-resize {
    z-index: 1;
}

/* Columns */
.ag-theme-alpine .ag-root-wrapper .ag-column-hover {
    background-color: rgba(120, 120, 120, 0.05);
}

/* Rows */
.ag-theme-alpine .ag-root-wrapper .ag-row {
    border-bottom: 0;
}

.ag-theme-alpine
.ag-root-wrapper
.ag-row
.ag-row-hover:not(.ag-full-width-row)::before,
.ag-theme-alpine
.ag-root-wrapper
.ag-row
.ag-row-hover.ag-full-width-row.ag-row-group::before {
    background-color: rgba(114, 174, 252, 0.1);
}

.ag-theme-alpine .ag-root-wrapper .ag-row.ag-row-selected::before {
    content: "";
    background-color: transparent;
}

.ag-theme-alpine .ag-root-wrapper .ag-row .product-odd-row {
    background-color: rgba(120, 120, 120, 0.085);
}

.ag-theme-alpine .ag-root-wrapper .ag-row .product-each-last-row {
    border-bottom: 0.5px solid #d4d4d1;
}

.ag-theme-alpine
.ag-root-wrapper
.ag-pinned-left-cols-container
.ag-row.ag-row-inline-editing {
    z-index: 0;
}

/* Cells */
.ag-theme-alpine
.ag-root-wrapper
.ag-row
.product-checkbox-cell
.ag-cell-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ag-theme-alpine .ag-root-wrapper .ag-row.ag-row-selected .product-price-cell,
.ag-theme-alpine
.ag-root-wrapper
.ag-row.ag-row-selected
.product-price-adjusted-cell.product-price-adjusted-selectbox-cell,
.ag-theme-alpine
.ag-root-wrapper
.ag-row.ag-row-selected
.product-price-adjusted-cell.product-price-adjusted-input-cell:not(
    .product-not-selectable-cell
  ) {
    background-color: rgba(114, 174, 252, 0.4);
}

.ag-theme-alpine
.ag-root-wrapper
.ag-row
.product-price-adjusted-cell
.ag-cell-value {
    display: grid;
}

.ag-theme-alpine .ag-root-wrapper .ag-row .product-editable-cell {
    padding: 0 4px;
}

.ag-theme-alpine
.ag-root-wrapper
.ag-row
.product-editable-cell
.ag-cell-wrapper {
    border-bottom: 0.5px solid #555;
    height: 20px;
    width: 100%;
}

.ag-theme-alpine
.ag-root-wrapper
.product-editable-cell
.ag-cell-wrapper
input {
    border: none;
    border-color: none;
    box-shadow: none;
}

.ag-theme-alpine .ag-root-wrapper .ag-row .out-of-stock-cell {
    color: lightgrey;
}

.chakra-portal {
    font-family: "Inter", sans-serif;
}